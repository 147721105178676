<template>
    <div>
    <div v-if="data != ''">
        <div class="fl-x">
            <div class="col-lg-11 col-11">
                <h4 class="text-seventh text-center">{{disease.name}}</h4>
            </div>
            <div class="col-lg-1">
                <i class="fa fa-heart text-danger text-fs-3" v-if="favSataus" @click="addFavourites"></i>
                <i class="fa fa-heart-o text-fs-3" v-else @click="addFavourites"></i>
            </div>
        </div>

        <div>
            <p class="text-dark font-weight-600 mb-1">Content</p>
            <div v-for="(item,index) in content" :key="index">
                <p class="text-4 pb-3">{{item.title}}</p>
                <p class="text-4 pb-3" v-html="item.description"></p>
            </div>
        </div>
        <div class="mt-3">
            <p class="text-dark font-weight-600 mb-1">Treatment Suggesstions</p>
            <p class="text-4">Antiseptic data analysis</p>

            <!--            <p class="text-4">No Gallery Images</p>-->
            <ImageGallery v-if="images!=''" :images="images"></ImageGallery>
            <div class="row">
                <div class="col-lg-6">
                    <p class="text-2 font-proxima-bold">Make Suggestion</p>
                    <validated-text-area v-model="suggested.content"></validated-text-area>
                    <div class="text-center">
                        <btn text="Suggest" :loading="loading" loading-text="Saving..." color="seventh" :border-radius="1" class="px-5" @click="addSuggestion()"></btn>
                    </div>
                </div>
                <div class="col-lg-6">
                    <p class="text-2 font-proxima-bold">Report Disease</p>
                    <validated-text-area v-model="report.content"></validated-text-area>
                    <div class="text-center">
                        <btn :loading="loading1" loading-text="Saving..." text="Report" color="seventh" :border-radius="1" class="px-5" @click="addItem()"></btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="fl-x fl-j-c" v-else>
                <loading-animation></loading-animation>
              <p>Loading...</p>
        </div>
    </div>
</template>

<script>
import ImageGallery from '@components/user-home/Gallery';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'DiseaseList',
    components: { ImageGallery },
    data () {
        return {
            favSataus: '',
            favUrl: urls.userHome.userHomeFavourites.add,
            suggestionUrl: urls.userHome.disease.suggestDisease,
            reportUrl: urls.userHome.disease.reportDisease,
            loading: false,
            loading1: false,
            response: '',
            data: [],
            disease: '',
            content: [],
            images: [],
            suggested_treatment: [],
            detailsUrl: urls.userHome.disease.detail,
            suggested: {
                disease: this.$route.params.id,
                content: ''
            },
            report: {
                content: '',
                disease_name: this.$route.params.id
            }
        };
    },
    mounted () {
        this.fetchItem();
    },
    methods: {
        async addFavourites () {
            try {
                const response = await axios.form(this.favUrl, { id: this.$route.params.id });
                const result = response.data;
                this.data = result;
                console.log('result', result);
                await this.fetchItem();
                if (result.success === true) {
                    const message = result.msg;
                    console.log('message', message);
                    this.$notify(message, 'Success', { type: 'success' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async addItem () {
            try {
                this.loading1 = true;
                this.response = await axios.form(this.reportUrl, this.report);
                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Reported Successfully', 'Success', { type: 'success' });
                    this.report.content = '';
                    this.loading1 = false;
                } else {
                    const errors = this.response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading1 = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async addSuggestion () {
            try {
                this.loading = true;
                this.response = await axios.form(this.suggestionUrl, this.suggested);

                const result = this.response.data;
                console.log('result', result);
                if (result.success === true) {
                    this.$notify('Suggestion Added Successfully', 'Success', { type: 'success' });
                    this.suggested.content = '';
                    this.loading = false;
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchItem () {
            console.log('Fetching details...');
            try {
                const response = await axios.form(this.detailsUrl, { id: this.$route.params.id });
                const result = response.data;
                this.data = result;
                console.log('result', result);
                this.disease = this.data.disease;
                this.favSataus = this.disease.has_favourited;
                console.log('fav State', this.favSataus);
                console.log('disease', this.disease);
                this.content = this.data.content;
                console.log('content', this.content);
                this.suggested_treatment = this.data.suggested_treatment;
                console.log('suggested_treatment', this.suggested_treatment);
                this.images = this.data.images;
                console.log('images', this.images);
                if (result.success === true) {

                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
